<template>
  <div id="gold-log-layout" v-loading="isLoading" :element-loading-text="$t('common.i_loading')" element-loading-background="rgba(0, 0, 0, 0.5)">
    <div class="gl-page-header">
      <el-page-header :title="$t('common.i_back')" :content="$t('index.i_coinsLog')" @back="goBack" style="float: left;"></el-page-header>
      <div style="float: right;background-color: white;padding: 2px 6px 3px 6px;border-radius: 20px;">
        <img src="@/assets/common/moneyIcon.png" style="width: 14px;vertical-align: middle;" alt="" />
        <span style="vertical-align: middle;text-decoration: none;color: black;font-size: 14px;padding-right: 2px;"> {{$store.state.goldNum}}</span>
      </div>
    </div>

    <div style="margin-left: 5%;margin-right: 5%;margin-top: 5%;overflow-y: scroll;height: 88vh;">
      <div v-infinite-scroll="loadMore" infinite-scroll-disabled="busy" infinite-scroll-distance="10">
        <div v-for="log in goldNumLog" :key="log.id" style="background-color: rgba(222, 231, 233, 1);margin-bottom: 3%;border-radius: 8px;padding: 4%;">
          <div style="overflow: hidden;color: black;">
            <div style="float: left;font-size: 22px;margin-top: 3%;">
              <div v-if="log.goldNum > 0" style="float: left;margin-top: 5%;">+{{log.goldNum}}</div>
              <div v-else style="float: left;margin-top: 5%;">{{log.goldNum}}</div>
              <img src="@/assets/common/moneyIcon.png" style="width: 30px;margin-left: 4px;" alt="" />
            </div>
            <div style="float: right;">
              {{log.desc}}
              <div style="margin-top: 10%;text-align: right;">
                {{log.logDate}}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="goldNumLog==''">
        <img width="150" src="@/assets/common/noIcon.png" style="margin-top: 30%;" alt="" />
        <div style="color: white;margin-top: 5%;">{{$t('inviteFriend.i_noLog')}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      goldNumLog: [],
      busy: false,
      pageNum: 0,
      isLast: false
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    loadMore: function () {
      if (this.isLast != true) {
        this.busy = true;
        this.pageNum++;
        this.isLoading = true;
        this.$axios({
          url: "/webAndGame/web/goldNumLog",
          method: "get",
          params: {
            pageNum: this.pageNum
          }
        }).then((result) => {
          this.isLoading = false;
          if (result.data.code == 1) {
            result.data.data.log.forEach((element) => {
              this.goldNumLog.push(element);
            });
            this.isLast = result.data.data.isLast;
          } else {
            this.$message({
              showClose: true,
              message: result.data.message,
              type: "error",
              center: true
            });
          }
        });
        this.busy = false;
      }
    }
  },
  created() {
    this.isLoading = true;
    this.$axios({
      url: "/webAndGame/web/goldNumLog",
      method: "get",
      params: {
        pageNum: 1
      }
    }).then((result) => {
      this.isLoading = false;
      if (result.data.code == 1) {
        this.$store.state.goldNum = result.data.data.goldNum;
      } else if (result.data.code == 2) {
        this.$store.state.goldNum = result.data.data.goldNum;
      } else {
        this.$message({
          showClose: true,
          message: result.data.message,
          type: "error",
          center: true
        });
      }
    });
  }
};
</script>

<style>
#gold-log-layout {
  width: 100%;
  height: 100%;
}
.gl-page-header {
  overflow: hidden;
  padding: 4% 4% 0 4%;
}
</style>
