<template>
  <div v-loading="isLoading" :element-loading-text="$t('common.i_loading')" element-loading-background="rgba(0, 0, 0, 0.5)">
    <div class="popup" v-show="show">
      <img v-if="$t('moenySteps.i_img')=='zh'" src="@/assets/common/openllq.png" style="width: 350px;float: right;" />
      <img v-if="$t('moenySteps.i_img')=='en'" src="@/assets/common/openllq1.png" style="width: 350px;float: right;" />
    </div>
    <div id="moneySteps-layout">
      <div class="ms-page-header">
        <el-page-header :title="$t('common.i_back')" :content="$t('moenySteps.i_earningSteps')" @back="$router.push('/index')" style="float: left"></el-page-header>
        <div style="float: right;height: 24px;">
          <svg style="vertical-align: middle;" t="1655965911443" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2350" width="24" height="24">
            <path d="M783.53 240.47C641.08 98.02 413.8 91.41 263.09 220.03V167.1c0-17.67-14.33-32-32-32s-32 14.33-32 32v128c0 17.67 14.33 32 32 32h128c17.67 0 32-14.33 32-32s-14.33-32-32-32h-48.15c125.55-101.54 310.66-94.06 427.34 22.62 124.75 124.77 124.75 327.8 0 452.56-124.78 124.75-327.78 124.75-452.56 0C225.28 677.84 192 597.48 192 512c0-17.67-14.33-32-32-32s-32 14.33-32 32c0 102.58 39.94 199.02 112.47 271.53 74.86 74.86 173.19 112.3 271.53 112.3 98.33 0 196.67-37.44 271.53-112.3 149.7-149.72 149.7-393.34 0-543.06z" p-id="2351" fill="#ffffff"></path>
            <path d="M512 288c-17.67 0-32 14.33-32 32v185.38c0 12.81 5 24.88 14.06 33.94l123.31 123.31c6.25 6.25 14.44 9.38 22.62 9.38s16.38-3.12 22.62-9.38c12.5-12.5 12.5-32.75 0-45.25L544 498.75V320c0-17.67-14.33-32-32-32z" p-id="2352" fill="#ffffff"></path>
          </svg>
          <router-link to="/goldLog" style="text-decoration: none;color: white;vertical-align: middle;">{{$t('index.i_coinsLog')}}</router-link>
        </div>
      </div>

      <div style="padding: 4%;">
        <div style="margin-top: 2%;">
          <img style="width: 34px;vertical-align: middle;" src="@/assets/common/moneyIcon.png" />
          <img style="width: 34px;vertical-align: middle;margin-left: 3%;margin-right: 3%;" src="@/assets/images/moneySteps002.png" />
          <img style="width: 38px;vertical-align: middle;" src="@/assets/images/moneySteps003.png" />
        </div>
        <div style="margin-top: 5%;">
          <span style="font-size: 14px;color: white;">*{{$t('moenySteps.i_moenyStepsMsg')}}*</span>
        </div>
        <div>
          <div style="text-align: left;margin-top: 5%;margin-bottom: 3%;">
            <img src="@/assets/images/steps001.png" style="width: 30px;vertical-align: middle;" />
            <span style="vertical-align: middle;margin-left: 3%;color: white;">{{$t('moenySteps.i_download')}}</span>
          </div>
          <div style="text-align: left;margin-left: 8%;">
            <div style="margin-left: 36px;">
              <img src="@/assets/common/gameIcon.png" style="border-radius: 10px;width: 60px;" />
            </div>
            <div style="margin-top: 3%;">
              <el-button type="primary" @click="downloadOk()" style="border-radius: 30px;width: 132px;height: 44px;background-color: rgba(234, 112, 48, 1);">
                <img width="16" src="@/assets/images/android.png" />
                &nbsp;
                <span style="font-size: 18px;">Android</span>
              </el-button>

              <!-- <el-button type="primary" @click="downloadOk()" style="width: 132px;border-radius: 30px;height: 44px;background-color: rgba(60, 88, 154, 1);">
                <img width="13" src="@/assets/images/ios.png" />
                &nbsp;
                <span style="font-size: 18px;">IOS</span>
              </el-button> -->
            </div>
          </div>

          <div v-if="isActivation == false">
            <div style="text-align: left;margin-top: 5%;margin-bottom: 3%;">
              <img src="@/assets/images/steps002.png" style="width: 30px;vertical-align: middle;" />
              <span style="margin-left: 3%;vertical-align: middle;color: white;">{{$t('moenySteps.i_activate')}}</span>
            </div>
            <div style="text-align: left;margin-left: 8%;">
              <div style="margin-left: 52px;">
                <img src="@/assets/images/moneySteps004.png" style="width: 60px;" />
              </div>
              <div style="">
                <span style="vertical-align: middle;margin-right: 3%;color: white;">
                  {{$t('moenySteps.i_status')}}：<span style="color: rgba(255, 246, 0, 1);">{{$t('moenySteps.i_activateN')}}</span>
                </span>
                <img width="30" src="@/assets/images/moneyStepsN.png" style="vertical-align: middle;" />
              </div>
              <div style="margin-top: 5%;">
                <el-button type="primary" @click="actDialog = true" :class="shrinkPacket" style="border-radius: 30px;height: 44px;min-width: 158px;background-color: rgba(72, 211, 123, 1);">{{$t('moenySteps.i_goActivate')}}</el-button>
              </div>
            </div>
          </div>

          <div v-if="isActivation == true">
            <div style="text-align: left;margin-top: 5%;margin-bottom: 3%;">
              <img src="@/assets/images/steps002.png" style="width: 30px;vertical-align: middle;" />
              <span style="margin-left: 3%;vertical-align: middle;color: white;">{{$t('moenySteps.i_activate')}}</span>
            </div>
            <div style="text-align: left;margin-left: 8%;">
              <div style="margin-left: 52px;">
                <img src="@/assets/images/moneySteps004.png" style="width: 60px;" />
              </div>
              <div style="">
                <span style="vertical-align: middle;margin-right: 3%;color: white;">
                  {{$t('moenySteps.i_status')}}：<span style="color: rgba(255, 246, 0, 1);">{{$t('moenySteps.i_activateY')}}</span>
                </span>
                <img width="30" src="@/assets/images/moneyStepsY.png" style="vertical-align: middle;" />
              </div>
              <div style="margin-top: 5%;">
                <el-button style="border-radius: 30px;width: 158px;background-color: rgba(35, 95, 172, 1);color: white;">{{$t('moenySteps.i_activateY')}}</el-button>
              </div>
            </div>
          </div>

          <div style="margin-top: 5%;margin-bottom: 3%;text-align: left;">
            <span style="">
              <img src="@/assets/images/steps003.png" style="width: 30px;vertical-align: middle;" />
              <span style="vertical-align: middle;color: white;">&nbsp;&nbsp;{{$t('moenySteps.i_getCoins')}}&nbsp;&nbsp;</span>
              <img src="@/assets/images/moneySteps003.png" style="width: 25px;vertical-align: middle;" />
            </span>
          </div>
          <div style="background-color: rgba(95, 126, 238, 1);color: white;border-radius: 10px;padding: 3% 0;margin: 0 3%;">
            <div style="text-align: left;margin-left: 5%;margin-bottom: 3%;">{{$t('moenySteps.i_howToGetCoins')}}</div>
            <div style="overflow: hidden;text-align: center;width: 100%;margin: auto;">
              <div style="float: left;width: 25%;">
                <img src="@/assets/images/moneySteps005.png" style="width: 40px;" />
                <div>{{$t('moenySteps.i_chest')}}</div>
              </div>
              <div style="float: left;width: 25%;">
                <img src="@/assets/images/moneySteps006.png" style="width: 40px;margin-top: 2px;margin-bottom: 1px;" />
                <div>{{$t('moenySteps.i_luckyDraw')}}</div>
              </div>
              <div style="float: left;width: 25%;">
                <img src="@/assets/images/moneySteps007.png" style="width: 40px;" />
                <div>{{$t('task.i_taskT')}}</div>
              </div>
              <div style="float: left;margin-top: 20px;width: 25%;">
                <svg t="1659086055309" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5513" width="32" height="32">
                  <path d="M221 592c-44.183 0-80-35.817-80-80s35.817-80 80-80 80 35.817 80 80-35.817 80-80 80z m291 0c-44.183 0-80-35.817-80-80s35.817-80 80-80 80 35.817 80 80-35.817 80-80 80z m291 0c-44.183 0-80-35.817-80-80s35.817-80 80-80 80 35.817 80 80-35.817 80-80 80z" p-id="5514" fill="#ffffff"></path>
                </svg>
              </div>
            </div>
          </div>
          <div style="margin-top: 5%;">
            <el-button type="primary" @click="moneyStepsOk()" style="text-align: center;border-radius: 30px;width: 158px;height: 44px;background-color: rgba(255, 39, 166, 1);">
              <img src="@/assets/images/moneySteps003.png" style="width: 16px;vertical-align: middle;" />&nbsp;
              <span style="font-size: 16px;vertical-align: middle;color: rgba(8, 50, 108, 1);font-weight: bold;">{{$t('common.i_startEarning')}}</span>
            </el-button>
            <el-button type="primary" @click="moneyStepsOk()" style="text-align: center;border-radius: 30px;width: 158px;height: 44px;background-color: rgba(255, 234, 0, 1);">
              <router-link to="/task" style="text-decoration: none;">
                <span style="font-size: 16px;vertical-align: middle;color: black;font-weight: bold;">{{$t('task.i_task')}}</span>
              </router-link>
            </el-button>
          </div>

          <div style="margin-top: 5%;margin-bottom: 3%;text-align: left;">
            <span style="">
              <img src="@/assets/images/steps004.png" style="width: 30px;vertical-align: middle;" />
              <span style="vertical-align: middle;color: white;">&nbsp;&nbsp;{{$t('moenySteps.i_coinsForCash')}}&nbsp;&nbsp;</span>
              <img src="@/assets/images/moneySteps008.png" style="width: 25px;vertical-align: middle;" />
            </span>
          </div>
          <div style="text-align: left;margin-top: 5%;margin-bottom: 10%;margin-left: 8%;">
            <el-button style="padding: 0;border-radius: 14px;position: relative;background: none;">
              <router-link to="/convert">
                <img width="164" src="@/assets/images/index002.png" />
                <span style="position: absolute;box-sizing: border-box;left: 12px;top: 12px;color: white;">
                  <span style="font-size: 14px;font-weight: bold;float: left;">{{$t('index.i_convert')}}</span>
                  <br />
                  <span style="font-size: 12px;font-weight: 100;margin-top: 3px;">{{$t('index.i_convertMsg')}}</span>
                </span>
              </router-link>
            </el-button>
          </div>
        </div>
      </div>
    </div>

    <el-dialog :title="$t('convert.i_tips')" :visible.sync="actDialog" width="80%" center :closeOnClickModal="false" :closeOnPressEscape="false" :showClose="false">
      <div style="text-align: center;">
        <span>{{$t('moenySteps.i_actTips')}}</span>
      </div>
      <div style="text-align: center;margin-top: 10%;">
        <el-button type="primary" @click="getActivation()">{{$t('convert.i_ok')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      activeName: "first",
      urlSchemes: "",
      isActivation: false,
      show: false,
      shrinkPacket: "",
      actDialog: false
    };
  },
  mounted() {
    document.addEventListener("visibilitychange", this.isVisible);
  },
  destroyed() {
    document.removeEventListener("visibilitychange", this.isVisible);
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    getMoneyStepsInfo() {
      this.isLoading = true;
      this.$axios({
        url: "/webAndGame/webUser/moneySteps",
        method: "get"
      }).then((result) => {
        this.isLoading = false;
        this.isActivation = result.data.data.isActivation;
        this.urlSchemes = result.data.data.urlSchemes;
      });
    },
    downloadOk() {
      window.location =
        "https://gamemobsoft.s3.ap-southeast-1.amazonaws.com/PlanetEvolution.html";
    },
    getActivation() {
      location.href = this.urlSchemes;
      this.actDialog = false;
    },
    isVisible: function (e) {
      if (e.target.visibilityState === "visible") {
        // 切入页面 显示页面
        if (this.isActivation == false) {
          //this.isActivation = true;
          this.getMoneyStepsInfo();
        }
      } else if (e.target.visibilityState === "hidden") {
        // 切出页面 隐藏页面
      }
    },
    moneyStepsOk() {
      if (!this.isActivation) {
        var timeCount = 3;
        clearInterval(this.timer);
        this.timer = setInterval(() => {
          if (timeCount % 2 != 0 && timeCount > 0) {
            timeCount--;
            this.shrinkPacket = "hd1";
          } else if (timeCount == 0) {
            this.shrinkPacket = "";
            clearInterval(this.timer);
            this.timer = null;
            timeCount = 3;
          } else if (timeCount % 2 == 0 && timeCount > 0) {
            timeCount--;
            this.shrinkPacket = "hd2";
          }
        }, 150);
      } else {
        location.href = this.urlSchemes.substring(
          0,
          this.urlSchemes.indexOf("//") + 2
        );
      }
    }
  },
  created() {
    this.getMoneyStepsInfo();
    var ua = navigator.userAgent;
    var isWeixin = !!/MicroMessenger/i.test(ua);
    if (isWeixin) {
      this.show = true;
    }
  }
};
</script>

<style>
#moneySteps-layout {
  width: 100%;
  height: 100%;
}
.ms-page-header {
  height: 24px;
  padding-top: 4%;
  margin-left: 4%;
  margin-right: 4%;
}
.hd1 {
  transform: rotate(-5deg);
  width: 50%;
  height: 50px;
}
.hd2 {
  transform: rotate(3deg);
  width: 50%;
  height: 50px;
}
.popup {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9998;
}
#moneySteps-layout .el-button {
  border: none;
}
</style>
